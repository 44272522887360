/*----------------------------------------------------------*\
    Header

    All styles for the main page header are below. This
    includes the navigation elements as well.

\*----------------------------------------------------------*/
$page-header-padding: 30px 0;
$page-header-logo-height: 40px;
$page-header-logo-height-med: 50px;
$main-nav-link-padding: 9px;
body.nav-overlay {
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	position: fixed;
}
.page-header {
	width: 100%;
	padding: $page-header-padding;
	top: 0;
	position: absolute;
	z-index: 100;
	.inner {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.logo {
		margin: 0 auto;
		img {
			height: $page-header-logo-height;
		}
	}
	.main-nav {
		display: none;
	}
	.nav-toggle {
		margin-left: 20px;
	}
	img {
		margin: 0;
	}
	li {
		display: inline-block;
	}
	nav a {
		display: inline-block;
		position: relative;
		padding-bottom: $main-nav-link-padding;
		overflow: hidden;
		&::after {
			content: '';
			background-color: $brand;
			width: 100%;
			height: 4px;
			display: block;
			position: absolute;
			bottom: 0;
			transform: translateX(-100%);
			transition: transform .2s;
		}
		&:hover, &:focus {
			color: $white;
			&::after {
				color: $white;
				transform: translateX(0)
			}
		}
	}
}

@include breakpoint(500px) {
	.page-header {
		.logo img {
			height: $page-header-logo-height-med;
		}
	}
}

@include breakpoint(800px) {
	.page-header {
		.nav-toggle {
			display: none;
		}
		.nav-right {
			display: block;
		}
		.main-nav {
			display: block;
		}
		.logo {
			margin: 0 20px 0 0;
			img {
				height: 61px;
			}
		}
	}
}
/*------------------------------------*\
    @group Navigation
\*------------------------------------*/
.main-nav {
	font-size: rem-calc(20);
	line-height: 1;
	a {
		color: $white;
		&:hover, &:focus {
			color: $brand;
		}
	}
	ul {
		padding: 0;
		margin: 0;
		li {
			margin: 0 0 0 50px;
			padding: 0;
			display: inline-block;
			&.selected a {
				color: $brand;
			}
			&:first-child {
				margin-left: 0;
			}
		}
	}
	nav {
		padding-top: $main-nav-link-padding;
	}
}

.offscreen-nav {
	background-color: $brand-accent;
	width: 100%;
	height: 100vh;
	text-align: center;
	padding: $page-header-padding;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: none;
	z-index: 500;
	.inner {
		position: relative;
	}
	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.logo {
		margin: 0 auto;
		img {
			height: $page-header-logo-height;
			margin: 0;
		}
	}
	.nav-toggle {
		margin-left: 20px;
	}

	.nav {
		max-width: 450px;
		padding-top: 50px;
		margin: 0 auto;
		a {
			font-size: rem-calc(28);
			font-weight: $main-bold;
			color: $white;
			line-height: 1;
			&:focus, &:hover {
				color: $brand;
			}
		}
		li {
			margin-bottom: 24px;
			&.selected a {
				color: $brand;
			}
		}
	}
}

@include breakpoint(500px) {
	.offscreen-nav {
		.logo img {
			height: $page-header-logo-height-med;
		}
	}
}

@include breakpoint(800px) {
	.offscreen-nav {
		display: none !important;
	}
}
/* @end Navigation */


/*------------------------------------*\
    @group Logo Animation
\*------------------------------------*/
.signal-1 {
  animation-duration: 3s;
  animation-name: signal;
  animation-iteration-count: infinite;
}

.signal-2 {
  animation-duration: 3s;
  animation-name: signal;
  animation-iteration-count: infinite;
  animation-delay: 1s;
}

.signal-3 {
  animation-duration: 3s;
  animation-name: signal;
  animation-iteration-count: infinite;
  animation-delay: 2s;
}

@keyframes signal {
	0% {
		fill: #5ed48e;
	}
	33% {
		fill: $white;
	}
	66% {
		fill: #5ed48e;
	}
	100% {
		fill: #5ed48e;
	}

}

/* @end Logo Animation */