/*----------------------------------------------------------*\
    Page Footer Section

    This file contains all styles for the footer section.

\*----------------------------------------------------------*/

.page-footer {
    background-color: $dark-green;
    color: $white;
    padding: 50px 0;
    .footer-text {
        margin-bottom: 48px;
    }
    .footer-form {
        margin-bottom: 70px;
    }
    .copyright {
        color: $light-gray;
        margin-bottom: 0;
    }
    .logo {
        margin-bottom: 12px;
        img {
            height: 40px;
            margin: 0;
        }
    }
    h2 {
        color: $white;
    }
    form {
        label {
            color: $light-gray;
            span {
                color: $brand;
            }
        }
        button {
            width: 100%;
            margin-bottom: 0;
        }
    }

}
#google-play-button {
    height: 80px;
}

@include breakpoint(medium) {
    .page-footer {
        .logo {
            margin-bottom: 21px;
            img {
                height: 60px;
            }
        }
        form {
            .field-group {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 30px;
            }
            .field-full {
                grid-column: span 2;
            }
            button {
                width: auto;
            }
        }
    }
}

@include breakpoint(850px) {
    .page-footer {
        .inner {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 30px 64px ;
        }
        .footer-text {
            margin-bottom: 0;
        }
        .footer-form {
            margin-bottom: 0;
        }
        .copyright {
            grid-column: span 2;
        }
    }
}

@include breakpoint(large) {
    .page-footer {
        .inner {
            grid-column-gap: 96px;
        }
        .logo {
            img {
                height: 70px;
            }
        }
    }
}