.text-grid-section {
	margin-bottom: 72px;
	h2 {
		text-align: center;
		margin-bottom: 46px;
	}
}

@include breakpoint(medium) {
	.text-grid-section {
		margin-bottom: 80px;
	}
}

.text-grid {
	display: grid;
	grid-gap: 20px 30px;
	h3 {
		color: $dark-gray;
	}
}

@include breakpoint(600px) {
	.text-grid {
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 30px;
	}
}