/*----------------------------------------------------------*\
    Main Content Section

    This file contains styles for the main content section,
    including sidebars.

\*----------------------------------------------------------*/

.page-content {
    background: $white;
	padding: 60px 0 36px;
    overflow: hidden;
    .inner {
    	max-width: 1100px;
    }
}