/*----------------------------------------------------------*\
    Slider

    All styles for sliders uses throughout the site are in
    this file.

\*----------------------------------------------------------*/


/*------------------------------------*\
    @group Gallery Slider
\*------------------------------------*/
//Gallery slider section wrapper
.gallery-slider-section {
	position: relative;
	.heading {
		text-align: center;
		margin-bottom: 20px;
	}
	.radial-bg {
		position: absolute;
		left: 70%;
		top: calc(50% + 60px);
		transform: translateY(-50%);
		z-index: -1;
		img {
			max-width: 535px;
		}
	}
}

@include breakpoint(medium) {
	.gallery-slider-section {
		.heading {
			margin-bottom: 48px;
		}
	}
}

@include breakpoint(800px) {
	.gallery-slider-section {
		.radial-bg {
			left: 65%;
			img {
				max-width: 700px;
			}
		}
	}
}

@include breakpoint(large) {
	.gallery-slider-section {
		.radial-bg {
			left: 50%;
			img {
				max-width: 1682px;
			}
		}
	}
}

//Gallery slider
.gallery-slider {
	background: $dark-gray;
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0 0 70px;
	border-radius: 20px;
	box-shadow: 0 0 32px #535857;
	position: relative;
	overflow: hidden;
	img {
		margin: 0;
	}
	.slick-slide {
		border-radius: 20px;
		overflow: hidden;
	}
}
.gallery-slider .slick-arrow {
	background-size: cover;
	width: 26px;
	height: 26px;
	font-size: 0;
	margin: 0;
	display: block;
	position: absolute;
	top: 50%;
	transform: translate(0, -50%);
	z-index: 10;
	overflow: hidden;
	opacity: 1;
	transition: all .3s;
	&::before,
	&::after {
		opacity: 1;
	}
	&.slick-prev  {
		background-image: url('/images/icons/arrow_left.svg');
		left: 10px;
	}
	&.slick-next {
		background-image: url('/images/icons/arrow_right.svg');
		left: auto;
		right: 10px;
	}
}

@include breakpoint(medium) {
	.gallery-slider {
		margin-bottom: 150px;
	}
	.gallery-slider .slick-arrow {
		width: 60px;
		height: 60px;
		&.slick-prev  {
			left: 50px;
		}
		&.slick-next {
			right: 50px;
		}
	}
}

/* @end */