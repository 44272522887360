/*----------------------------------------------------------*\
    Variables

    This file contains all (non-Foundation) globally-scoped
    variables used throughout the Sass files. We strongly
    encourage sticking to these variables instead of
    hard-coded values as often as possible.

\*----------------------------------------------------------*/

//Font families
$sans-serif-font: "ubuntu", "Segoe UI", "Roboto", "Droid Sans", "Helvetica Neue", sans-serif;
$serif-font: sans-serif;
$heading-font: $sans-serif-font;
$main-font: $sans-serif-font;
$alt-font: $heading-font;

//Font weights
$main-normal: 400;
$main-bold: 700;
$alt-normal: 400;
$alt-bold: 700;
$heading-normal: $alt-normal;
$heading-bold: $alt-bold;

//Font sizes
$font-sizes: (
  small: (rem-calc(13), 1.23),
  default: (rem-calc(16), 1.5),
  large: (rem-calc(23), 1.09),
  small-desktop: (rem-calc(15), 1.33),
  default-desktop: (rem-calc(18), 1.5),
  large-desktop: (rem-calc(26), 1.35)
);


//Styleguide colors
$brand:				 #5ed48e;
$brand-accent:		 #272e2c;
$brand-alt:			 $brand;
$brand-alt-accent:	 $brand-accent;
$light-green:        #558d6e;
$dark-green:		 #1c2120;
$light-gray:         #b8c6be;
$dark-gray:          #535857;
$orange:             #f6a04d;
$white:              #FFFFFF;
$black:              #000000;
$gray:               gray;

$colors: (
	'brand':			 $brand,
	'brand-accent':		 $brand-accent,
	'brand-alt':		 $brand,
	'brand-alt-accent':	 $brand-accent,
	'light-green':       $light-green,
	'light-gray':        $light-gray,
	'dark-gray':         $dark-gray,
	'orange':            $orange,
	'white':             $white,
	'black':             $black
);

//Gradients, for use on backgrounds only
$gradients: (
	'white': linear-gradient(180deg, rgba(231, 231, 231, 1) 0%, rgba(255, 255, 255, 1) 100%)
);

//Transparent background colors
$brand-trans:        rgba($brand,0.8);
$black-trans:        rgba($black,0.7);

// Main body color, use when adding this color elsewhere (form fields as an example)
$main-color: $dark-gray;

//Link colors
$link-base: $orange;
$link-active: darken($orange, 10%);

//Social media brand colors
$social: (
	'twitter': 		 #00aced,
	'facebook': 	 #3b5998,
	'googleplus': 	 #dd4b39,
	'pinterest': 	 #cb2027,
	'linkedin': 	 #007bb6,
	'youtube': 		 #bb0000,
	'vimeo': 		 #aad450,
	'instagram': 	 #517fa4,
	'quora': 		 #a82400,
	'foursquare': 	 #0072b1,
	'stumbleupon': 	 #EB4823,
	'soundcloud': 	 #ff3a00
);

//Shadows
$text-shadow: 1px 0 1px rgba(0,0,0,0.65);
$text-shadow-light: 1px 1px 1px rgb(255,255,255);
$box-shadow: 0 1px 1px rgba(0,0,0,1);

//Rounded corners
$border-round: 1000px;
$border-radius: 5px;

//Section spacing
$full-section-spacing-small: 		60px;
$content-section-spacing-small: 	90px;
$content-sub-section-spacing-small: 60px;
$intro-section-spacing-small: 		30px;
$full-section-spacing: 				90px;
$content-section-spacing: 			150px;
$content-sub-section-spacing: 		90px;
$intro-section-spacing: 			60px;

//Hero backgrounds
$hero-bg: (
	'home-orange': test
);

$hero-bg-color: (
	'home-orange': #ff9900
);