.email-callout {
	max-width: 512px;
	background-color: $dark-green;
	padding: 20px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 24px;
	border-radius: $border-radius;
	.heading {
		font-size: rem-calc(18);
		font-weight: $main-bold;
		color: $white;
		text-align: center;
		margin-bottom: 24px;
	}
	button, .button {
		width: 100%;
		margin-bottom: 0;
		display: block;
	}
}

@include breakpoint(medium) {
	.email-callout {
		.heading {
			text-align: left;
			margin-bottom: 20px;
		}
		form {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		input[type="email"] {
			flex-grow: 1;
			margin-right: 20px;
			margin-bottom: 0;
		}
		button {
			width: auto;
			display: inline-block;
		}
	}
}


//Wide version
.email-callout.wide {
	max-width: 860px;
	form {
		max-width: 475px;
	}
}

@include breakpoint(medium) {
	.email-callout.wide {
		p {
			font-size: rem-calc(20);
		}
		.heading {
			font-size: rem-calc(18);
		}
	}
}