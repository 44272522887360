$quote-horizontal-padding: 20px;
.quote-callout {
	background-color: $brand-accent;
	font-size: rem-calc(18);
	color: $white;
	padding: 68px $quote-horizontal-padding 50px;
	margin: 0 -20px 72px;
	position: relative;
	&::before {
		content: '';
		width: 88px;
		height: 74px;
		background-image: url('/images/icons/quote.svg');
		display: block;
		position: absolute;
		top: -26px;
		left: $quote-horizontal-padding;
	}
	p {
		color: $light-gray;
		font-style: italic;
	}
	img {
		border-radius: $border-radius;
		margin: 0;
	}
	.quotee {
		font-weight: $main-bold;
		color: $white;
		font-style: normal;
		margin-bottom: 0;
		.title {
			color: $brand;
			display: block;
		}
	}
	.quote-text {
		margin-bottom: 30px;
	}
	.quote-image {
		text-align: center;
	}
}

@include breakpoint(medium) {
	.quote-callout {
		border-radius: $border-radius;
		margin: 0 0 150px;
	}
}

@include breakpoint(750px) {
	.quote-callout {
		display: flex;
		justify-content: space-between;
		.quote-image {
			flex-basis: 42%;
		}
		.quote-text {
			flex-basis: 55%;
			margin-bottom: 0;
		}
	}
}

@include breakpoint(1050px) {
	.quote-callout {
		padding-left: 50px;
		padding-right: 0;
		.quote-text {
			padding-right: 50px;
		}
		.quote-image {
			border-radius: 0 4px 4px 0;
			overflow: hidden;
			flex-basis: 539px;
			margin-top: -68px;
			margin-bottom: -50px;
			img {
				border-radius: 0;
			}
		}
	}
}