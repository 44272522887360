$media-object-text-padding: 40px;

.media-object {
	max-width: 400px;
	margin: 0 auto 72px;
	position: relative;
	z-index: 20;
	.media {
		margin-bottom: 10px;
		img, svg {
			max-width: 200px;
			display: block;
			margin: 0 auto;
		}
	}
}

@include breakpoint(medium) {
	.media-object {
		max-width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.text {
			padding-left: $media-object-text-padding;
		}
	}
	//Alternate image sides
	.media-object-group {
		max-width: 1005px;
		margin: 0 auto;
		.media-object:nth-child(2n+2) {
			.media {
				order: 1;
			}
			.text {
				padding-left: 0;
				padding-right: $media-object-text-padding;
				order: 0;
			}
		}
	}
}

@include breakpoint(800px) {
	.media-object {
		.media {
			img, svg {
				max-width: 300px;
			}
		}
	}
}

@include breakpoint(large) {
	.media-object {
		margin-bottom: 150px;
		.media {
			margin: 0 48px 0 0;
			img, svg {
				max-width: 375px;
			}
		}
		.text {
			flex-basis: 50%;
			padding: 0;
		}
	}
	.media-object-group {
		.media-object:nth-child(2n+2) {
			.media {
				margin: 0 0 0 48px;
			}
			.text {
				padding: 0;
			}
		}
	}
}