@font-face {
    font-family: 'ubuntu';
    src: url('/fonts/ubuntu-regular-webfont.eot');
    src: url('/fonts/ubuntu-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/ubuntu-regular-webfont.woff2') format('woff2'),
         url('/fonts/ubuntu-regular-webfont.woff') format('woff'),
         url('/fonts/ubuntu-regular-webfont.ttf') format('truetype'),
         url('/fonts/ubuntu-regular-webfont.svg#ubunturegular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ubuntu';
    src: url('/fonts/ubuntu-bold-webfont.eot');
    src: url('/fonts/ubuntu-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/ubuntu-bold-webfont.woff2') format('woff2'),
         url('/fonts/ubuntu-bold-webfont.woff') format('woff'),
         url('/fonts/ubuntu-bold-webfont.ttf') format('truetype'),
         url('/fonts/ubuntu-bold-webfont.svg#ubuntubold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}