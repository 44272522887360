.animation-overflow {
	overflow: visible;
}

.clock-animate {
	transform-origin: 53% 53%;
	animation: spin 12s linear infinite;
}

.cloud-animate {
	transform-origin: 20.5% 22.5%;
	animation: spin 4s linear infinite;
}

.gps-scale {
	animation: resize 2s linear infinite;
}

@for $i from 1 through 9 {
  .cloud-dot-#{$i} {
  	animation: fill-dot 9s steps(1) infinite;
  	animation-delay: $i * (.5s);
  }
}

// .cloud-dot-1 {
// 	animation: fill-dot 9s steps(1) infinite;
// }
// .cloud-dot-2 {
// 	animation: fill-dot 9s steps(1) infinite;
// 	animation-delay: 1s;
// }
// .cloud-dot-3 {
// 	animation: fill-dot 9s steps(1) infinite;
// 	animation-delay: 2s;
// }
// .cloud-dot-4 {
// 	animation: fill-dot 9s steps(1) infinite;
// 	animation-delay: 3s;
// }
// .cloud-dot-5 {
// 	animation: fill-dot 9s steps(1) infinite;
// 	animation-delay: 4s;
// }
// .cloud-dot-6 {
// 	animation: fill-dot 9s steps(1) infinite;
// 	animation-delay: 5s;
// }
// .cloud-dot-7 {
// 	animation: fill-dot 9s steps(1) infinite;
// 	animation-delay: 6s;
// }
// .cloud-dot-8 {
// 	animation: fill-dot 9s steps(1) infinite;
// 	animation-delay: 7s;
// }
// .cloud-dot-9 {
// 	animation: fill-dot 9s steps(1) infinite;
// 	animation-delay: 8s;
// }

@keyframes fill-dot {
	0% {
		fill: #27f57b;
	}
	50% {
		fill: $white;
	}
	100% {
		fill: #27f57b;
	}
}

@keyframes resize {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.05);
	}
}

@keyframes spin {
	100% {
		transform: rotate(360deg);
	}
}