.process-diagram {
	&.horizontal {
		display: none;
	}
	.data-dot {
	    display: none;
	    width: 10px;
	    height: 10px;
	    position: absolute;
	    z-index: 10;
	}
	.data-dot svg {
	    height: 20px;
	    width: 20px;
	    transform: translate(-12px, -12px);
	}
	.dashed-outline {
	    stroke: #5ed48e;
	    stroke-width: 2;
	    fill: none;
	    stroke-dasharray: 9;
	}
}

@include breakpoint(750px) {
	.process-diagram {
		&.vertical {
			display: none;
		}
		&.horizontal {
			display: block;
		}
	}
}