/*------------------------------------*\
    @group Hero
\*------------------------------------*/
.hero {
	background-image: url('/images/bg/bg_radial_dots_quarter.svg');
	background-repeat: no-repeat;
	background-color: $brand-accent;
	background-position: calc(100% + 2px) calc(0 - 2px);
	background-size: 280px 280px;
	font-size: rem-calc(18);
	color: $light-gray;
	text-align: center;
	padding-top: 130px;
	padding-bottom: 190px;
	position: relative;
	h1 {
		color: $white;
		line-height: 1.1;
	}
	.subheading {
		font-size: rem-calc(16);
		color: $brand;
		margin-bottom: 0;
	}
	p {
		max-width: 800px;
		margin-left: auto;
		margin-right: auto;
	}
	.hero-text {
		margin-bottom: 54px;
	}
	.email-callout-wrapper {
		width: 100%;
		padding-left: 20px;
		padding-right: 20px;
		margin-bottom: 0;
		position: absolute;
		left: 50%;
		bottom: 0;
		transform: translate(-50%, 50%);
	}
	.email-callout {
		margin-bottom: 0;
	}
}

@include breakpoint(medium) {
	.hero {
		background-size: 550px 550px;
	}
}

@include breakpoint(large) {
	.hero {
		background-size: 850px 850px;
		font-size: rem-calc(20);
		padding-top: 200px;
		padding-bottom: 170px;
		.hero-text {
			margin-bottom: 86px;
		}
	}
}
//Loops over $hero-bg in _variables.scss and creates files with mixin
@each $name, $filename in $hero-bg {
  .#{$name} {
	@include hero-image($filename: $filename);
	background-color: map-get($hero-bg-color, $name);
  }
}

/* @end Hero */