.feature-cards-section {
	background-color: $brand-accent;
	background-image: url('/images/bg/bg_spray.svg');
	background-position: -2px -2px;
	background-repeat: no-repeat;
	background-size: 100% auto;
	font-size: rem-calc(18);
	color: $light-gray;
	padding: 70px 0 46px;
	h2 {
		color: $white;
	}
	.section-heading {
		margin-bottom: 42px;
	}
}

@include breakpoint(medium) {
	.feature-cards-section {
		font-size: rem-calc(20);
	}
}

@include breakpoint(large) {
	.feature-cards-section {
		background-size: 1000px 1000px;
	}
}

.feature-cards {
	font-size: rem-calc(16);
	color: $dark-gray;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
	grid-gap: 30px;
	margin-bottom: 70px;
	.card {
		background-color: $white;
		padding: 20px;
		border-radius: 10px;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		> *:last-child {
			margin-bottom: 0;
		}
	}
	.tag {
		text-align: right;
		margin-top: auto;
		span {
			background-color: $brand-accent;
			font-size: rem-calc(14);
			font-weight: $main-bold;
			color: $brand;
			line-height: 1;
			padding: 6px 9px;
			border-radius: 4px;
		}
	}
	.card-dark {
		background-color: darken($light-green, 15%);
		color: $white;
		a {
			color: lighten($orange, 15%);
			&:hover, &:focus {
				color: lighten($orange, 22%);
			}
		}
		h3 {
			color: $white;
		}
	}
}

@include breakpoint(medium) {
	.feature-cards {
		margin-bottom: 90px;
	}
}