.page-heading {
	background-color: $brand-accent;
	background-image: url('/images/bg/bg_radial_dots_quarter.svg');
	background-repeat: no-repeat;
	background-position: calc(100% + 2px) calc(0 - 2px);
	background-size: auto 200px;
	.page-header {
		position: relative;
	}
}

@include breakpoint(medium) {
	.page-heading {
		background-size: auto 400px;
	}
}

@include breakpoint(1000px) {
	.page-heading {
		background-size: auto 600px;
	}
}